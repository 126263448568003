/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // cookies banner
        $('#cookie-banner').css('display','block').animate({bottom: '0px'});

        $('#cookie-banner .closeCookie').on('click', function(e){
          document.cookie="www-aguarecienhecha-es-eu-cookie=1";
          $('#cookie-banner').hide();
        });
        $(".js-scroll-ancla").on('click', function(event){
          event.preventDefault();
          var hash = $(this).attr("href");
          var destino=$(hash).position().top;//-$("header").height();
          TweenMax.to(window, 1, {scrollTo:destino,ease:Power2.easeInOut});
        }); 
        $(".contacto").click(function(event){
            $(this).toggleClass("activo");
            $(".cont-contacto").toggleClass("activo");
        });
        $(".cont-contacto .close").click(function(event){
            $(".contacto").removeClass("activo");
            $(".cont-contacto").removeClass("activo");
        });

        detectBrowser();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        /*
        alturaMinima=jQuery(".grid-massonry .mod1").height();
        console.log("******");
        console.log(jQuery(window).innerHeight()+" **** "+alturaMinima);
        if (jQuery(window).innerWidth() < 767 && jQuery(window).innerHeight()>alturaMinima ) {
          jQuery(".grid-massonry .mod1").css("height","100vh");
          jQuery(".grid-massonry .mod1").css("min-height",alturaMinima+"px");
        }
        */

        // $(window).resize(function () {
        //     if (jQuery(window).innerWidth() < 992) {
        //         jQuery('.grid-massonry').masonry('destroy');
        //     } else {
        //         $('.grid-massonry').masonry({
        //           // options
        //           itemSelector: '.col-12',
        //           columnWidth: '.modulo'
        //         });
        //     }
        // });


        var inter = setInterval(function(){
          $('.noticia').each(function(index, el) {
              if($(el).find('.triangle-border').length>1){
                 var actual = $(el).find('.triangle-border:visible');   
                 var next = $(actual).next();
                 if(!$(next).hasClass('triangle-border')){
                  next = $(el).find('.triangle-border:first');
                 }
                 console.log(next);
                 actual.fadeOut('slow', function() {
                   next.fadeIn('slow');
                 });
              }
          });
        },5000);


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_002_guia': {
      init: function() {
        // JavaScript to be fired on the about us page
        var lat = getParameterByName('lat'); 
        var lng = getParameterByName('lng'); 
        if(lat == null || lng == null || lat == '' || lng ==''){
          if(navigator.geolocation){     
            // console.log('Obteniendo posicion');
            navigator.geolocation.getCurrentPosition(getPosicion,errorPosicionar);
          }else{
            console.log('El navegador no soporta posicionamiento');
          }   
        }
        $(".js-enlace-mapa").click(function(event){
          event.preventDefault();
          var lat=$(this).closest(".cont-info").data("lat");
          var lng=$(this).closest(".cont-info").data("lng");
          window.open("https://www.google.es/maps/@"+lat+","+lng+",15z?hl=es");
        });

        $(".js-enlace-chicheta").click(function(event){
          event.preventDefault();
          var chincheta=$(this).closest(".cont-info").data("chincheta");
          window.open(chincheta);
        });

      }
    },
    'single': {
      init: function(){
        console.log('single'); 
        var cont_activa = 0;
        $('.cont-slide-noticias .item').each(function(index, el) {
          if($(el).find('a.active').length > 0){
            cont_activa = index;
            return false; 
          }
        });
      $('.cont-slide-noticias').slick({
          infinite: false,
          slidesToShow: 8,
          slidesToScroll: 1,
          // variableWidth: true,
          prevArrow:'<button type="button" class="slick-prev"><i class="fa fa-caret-left" aria-hidden="true"></i></button>',
          nextArrow:'<button type="button" class="slick-next"><i class="fa fa-caret-right" aria-hidden="true"></i></button>',
          responsive: [
                      {
                        breakpoint: 1000,
                        settings: {
                          slidesToShow: 6,
                          slidesToScroll: 1
                        }
                      },
                      {
                        breakpoint: 800,
                        settings: {
                          slidesToShow: 4,
                          slidesToScroll: 1
                        }
                      },
                      {
                       breakpoint: 600,
                        settings: {
                          slidesToShow: 1,
                          initialSlide:cont_activa,
                          slidesToScroll: 1
                        } 
                      }
                      // You can unslick at a given breakpoint now by adding:
                      // settings: "unslick"
                      // instead of a settings object
                    ]
        }); 

  
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.




function errorPosicionar(error) {
    switch(error.code)  
    {  
        case error.TIMEOUT:  
            console.log('Request timeout');
            err="[-1]";
        break;  
        case error.POSITION_UNAVAILABLE:  
            console.log('Tu posición no está disponible');  
            err="[-2]";
        break;  
        case error.PERMISSION_DENIED:  
            console.log('Tu navegador ha bloqueado la solicitud de geolocalización');  
            err="";
        break;  
        case error.UNKNOWN_ERROR:  
            console.log('Error desconocido');  
            err="[-3]";
        break;  
    }  
    jQuery("#modalAlerta .texto").html( jQuery("#modalAlerta .texto").html()+" "+err);
    jQuery("#modalAlerta").modal("show");
}

function getPosicion(pos, z){
    var lat = pos.coords.latitude;
    var lng = pos.coords.longitude;

    self.location.href += '?lat='+lat+'&lng='+lng;


    
}
function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}




function detectBrowser(){
  // Opera 8.0+
  var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

  // Firefox 1.0+
  var isFirefox = typeof InstallTrigger !== 'undefined';

  // Safari 3.0+ "[object HTMLElementConstructor]" 
  var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification);

  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/false || !!document.documentMode;

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;

  // Chrome 1+
  var isChrome = !!window.chrome && !!window.chrome.webstore;

  // Blink engine detection
  var isBlink = (isChrome || isOpera) && !!window.CSS;


  if(isOpera){
      jQuery('body').addClass('browser-opera');    
  }

  if(isFirefox){
      jQuery('body').addClass('browser-firefox');    
  }

  if(isSafari){
      jQuery('body').addClass('browser-safari');    
  }

  if(isIE){
      jQuery('body').addClass('browser-ie');    
  }

  if(isEdge){
      jQuery('body').addClass('browser-edge');    
  }
  if(isChrome){
      jQuery('body').addClass('browser-chrome');    
  }
  if(isBlink){
      jQuery('body').addClass('browser-blink');    
  }


}